$enable-shadows: true
$spacer: 1rem

// Brand colors
$brand-green: #3ea428

// Color values (based on Bootstrap v4.5 defaults)
$blue:    #007bff
$indigo:  #6610f2
$purple:  #6f42c1
$pink:    #e83e8c
$red:     #dc3545
$orange:  #fda714 // was #fd7e14
$yellow:  #ffd500 // was #ffc107
$green:   $brand-green // was #28a745
$teal:    #20c997
$cyan:    #0dcaf0 // was #17a2b8

$white: #fff
$gray-100: #f7f7f9 // was #f8f9fa
$gray-200: #eceeef // was #e9ecef
$gray-300: #dee2e6
$gray-400: #ced4da
$gray-500: #adb5bd
$gray-600: #636c72 // was #6c757d
$gray-700: #464a4c // was #495057
$gray-800: #292b2c // was #343a40
$gray-900: #212529
$black: #000

// Application colors
$primary: $brand-green
$secondary: $white
$success: $green
$info: $blue
$warning: $yellow
$danger: $red
$dark: $gray-800

$body-bg: $white
$body-color: $gray-900

$text-warning: $orange // not a Bootstrap variable
$border-warning: $orange // not a Bootstrap variable
$form-feedback-warning-color: $orange // not a Bootstrap variable

// Typography
$font-family-sans-serif: Ubuntu, Arial, Nimbus Sans L, sans-serif // Ubuntu font is loaded via Google fonts, see _fonts.sass

$font-size-base: 1rem
$blockquote-font-size: $font-size-base

$line-height-base: 1.5
$headings-line-height: 1.1

// Forms
$form-check-input-margin-y: .35rem
$form-text-margin-top: 0

$enable-validation-icons: false
$form-validation-states: ("warning": ("color": $form-feedback-warning-color))

// Buttons
$btn-padding-y: .5rem
$btn-padding-x: 1rem
$btn-line-height: 1.25
$btn-line-height-sm: 1.25

$btn-box-shadow: inset 1px 1px 0 rgba($white, .25), .15rem .15rem 0 rgba($black, .15)
$btn-box-shadow-sm: inset 1px 1px 0 rgba($white, .25), .1rem .1rem 0 rgba($black, .15) // not a Bootstrap variable
$btn-box-shadow-secondary: .15rem .15rem 0 rgba($black, .08) // not a Bootstrap variable

// Navigation
$navbar-padding-y: 0
$navbar-padding-x: 0

$navbar-light-color: rgba($dark, .8)
$navbar-light-hover-color: $dark
$navbar-light-active-color: $black

$navbar-dark-color: rgba($white, .75)
$navbar-dark-hover-color: rgba($white, .95)
$navbar-dark-active-color: $white

$nav-link-padding-x: $spacer
$nav-link-padding-y: .75rem

// Tables
$table-hover-bg: $gray-100
$table-sm-cell-padding: .25rem // like .p-1

// Custom controls
$custom-file-text: (de: "Durchsuchen")
$custom-control-spacer-x: 0
$custom-control-indicator-checked-bg: $dark
$custom-control-indicator-active-bg: lighten($dark, 35%)
$custom-control-indicator-disabled-bg: #ddd
$custom-control-indicator-checked-disabled-bg: $gray-600
$custom-control-label-disabled-color: $body-color

// Letters
$print-page-size: 'A4'
$print-body-min-width: 720px

@import ~bootstrap/scss/functions
@import ~bootstrap/scss/variables
@import ~@fortawesome/fontawesome-free/scss/functions
@import ~@fortawesome/fontawesome-free/scss/variables

// Variables for content placement
$navigation-height: 2 * $nav-link-padding-y + $line-height-base * 1rem
$content-top-desktop: 2 * $navigation-height + $spacer
$content-top-mobile: $navigation-height + $spacer
