@import ~bootstrap/scss/mixins

=only-in-tests
  body[data-environment='test'] &
    @content

=not-in-tests
  body:not([data-environment='test']) &
    @content

=icon($character)
  font-family: var(--fa-style-family-classic)
  content: "#{$character}"

=ellipsis
  overflow: hidden
  text-overflow: ellipsis

// Similar to Bootstrap's media-breakpoint-down mixin, but excludes the given breakpoint name.
// For example, media-breakpoint-less(md) applies to xs, and sm, but won't apply to md, lg, or xl.
=media-breakpoint-less($name)
  +media-breakpoint-down(breakpoint-previous($name))
    @content

// Similar to Bootstrap's breakpoint-next function, this returns the previous breakpoint to a given name:
//
//   >> breakpoint-previous(md)
//   sm
//
@function breakpoint-previous($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints))
  $n: index($breakpoint-names, $name)
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n - 1), null)

=margin-x($margin)
  margin-left: $margin
  margin-right: $margin

=margin-y($margin)
  margin-top: $margin
  margin-bottom: $margin

=padding-x($padding)
  padding-left: $padding
  padding-right: $padding

=padding-y($padding)
  padding-top: $padding
  padding-bottom: $padding

// For spacing classes like .mr-4 or .py-3 Bootstrap uses a defined lines of size steps.
// You should prefer using those over custom spacing sizes whenever possible, for the sake of consistency.
//
// Example usage:
//
//   margin-right: spacer(4)
//
@function spacer($length)
  @return map-get($spacers, $length)
