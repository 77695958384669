@import ../environment

$margin-bottom: .5rem

$field-padding-y: .25rem
$field-padding-x: .5rem

$cell-padding: .25rem

.snapshot-html

  h1, h2, h3
    font-size: $h4-font-size
    margin-top: 3 * $margin-bottom

  [snapshot-hidden]
    display: none

  p,
  [snapshot-label],
  [snapshot-field]
    margin-bottom: $margin-bottom

  td
    > p,
    > [snapshot-label],
    > [snapshot-field]
      &:last-child
        margin-bottom: 0

  [snapshot-label]:not([snapshot-hidden]) + [snapshot-field]
    margin-top: -1 * $margin-bottom

  [snapshot-field]
    background-color: $gray-200
    border: 1px solid $gray-200
    min-height: 1rem * $line-height-base + 2 * $field-padding-y
    padding: $field-padding-y $field-padding-x
    & + [snapshot-small]
      margin-top: -.5 * $margin-bottom

  [snapshot-checked]
    padding-left: 1.5rem
    position: relative

    &:before
      position: absolute
      top: 0
      left: .25em
      transform: scale(1.5)

      display: block
      width: 1em
      height: 1.2em
      line-height: 1.4

    &[snapshot-checked='true']
      &:before
        content: '☒'

    &[snapshot-checked='false']
      &:before
        content: '☐'

  [snapshot-field='number']
    text-align: right
    white-space: nowrap

  [snapshot-field='textarea']
    white-space: pre-line

  [snapshot-small]
    font-size: 80%

  table
    border-collapse: collapse
    width: 100%

  th
    font-weight: bold

  th,
  td
    border-top: 1px solid #ccc
    vertical-align: top
    padding: $cell-padding

  thead
    th,
    td
      vertical-align: bottom

    tr:first-child
      th,
      td
        border-top: none
