@import ../environment

$desktop-navigation-up: lg

=desktop-navigation
  +media-breakpoint-up($desktop-navigation-up)
    @content

=mobile-navigation
  +media-breakpoint-down(breakpoint-previous($desktop-navigation-up))
    @content

.navigation
  +not-in-tests
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: $zindex-fixed
    &.-tour
      position: absolute
      z-index: auto

  .navbar-text,
  .nav-link,
  .nav-emblem
    padding: $nav-link-padding-y $nav-link-padding-x !important
    white-space: nowrap
  .nav-emblem
    width: 45px
    position: fixed
    &.-left
      left: 0
    &.-right
      right: 0
    +media-breakpoint-down(breakpoint-previous($desktop-navigation-up))
      display: none

  +mobile-navigation
    .container
      padding: 0

.navigation--content-spacer
  +desktop-navigation
    height: $content-top-desktop
  +mobile-navigation
    height: $content-top-mobile
  +only-in-tests
    height: $spacer

.navigation,
.navigation--content-spacer
  @media print
    display: none

.navigation--primary-menu
  &,
  .nav-link
    background-color: $dark

  .badge
    vertical-align: .15rem

  +media-breakpoint-only(lg)
    .nav-link.-power-user
      .icon-text
        display: none

  +mobile-navigation
    overflow-x: auto
    .icon-text
      display: none

.navigation--secondary-menu
  box-shadow: 0 1px 0 rgba(#000, .1)
  opacity: 0

  max-height: 0
  overflow-x: hidden
  overflow-y: auto

  transition: opacity .15s ease-out

  &,
  .nav-link
    background-color: darken($body-bg, 3%) // like .bg-light

  &.-open
    opacity: 1
    max-height: calc(100vh - #{$navigation-height})

  +desktop-navigation
    &.-active
      opacity: 1
      max-height: calc(100vh - #{$navigation-height})

  +mobile-navigation
    box-shadow: 0 0 2.5rem 1rem rgba(#000, .2), 0 1px 0 rgba(#000, .1)

    .navbar-nav
      flex-direction: column

  .nav-link
    overflow: hidden
    text-overflow: ellipsis

.navigation--row
  width: 100%
  display: flex
  flex-direction: row

.navigation--spacer
  margin-right: auto
