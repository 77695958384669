@use "sass:math"
@import ../environment

.custom-control
  margin-bottom: math.div($spacer, 2)

  &:last-child,
  .input-group-append &
    margin-bottom: 0

  td:not([checkable-cell]) > &
    margin-top: math.div($spacer, 2)

.custom-control-input
  body[data-environment="test"] &
    opacity: 0.5
    z-index: 1

.custom-control--click-helper,
.custom-control-label
  cursor: pointer

  input:disabled ~ &
    cursor: not-allowed

.custom-control--click-helper
  // Helper element to allow clicking the gutter between custom indicator and label.
  // Element and click handler defined via Unpoly compiler for .custom-control.
  position: absolute
  top: 0
  left: $custom-control-indicator-size
  width: $custom-control-gutter
  height: $font-size-base * $line-height-base
