@import '../mixins'

// These variables are set to 0 by default, which causes bad behaviour, because calc(0 + 2rem) is 0
\:root
  --ts-pr-clear-button: 0px
  --ts-pr-caret: 0px

.input-hidden .ts-control > input
  +only-in-tests
    opacity: 1
    position: inherit
    left: inherit
