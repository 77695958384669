@import ../environment

.form-help
  margin-top: .2rem
  color: $text-muted
  padding-left: 1.25em

  position: relative
  &::before
    position: absolute
    top: 0
    left: 0
    +icon($fa-var-info-circle)

  &.-bottom-separator
    padding-bottom: .75rem
    border-bottom: 1px solid rgba(#000, .125)
