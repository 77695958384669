@import ../environment

=gallery-image-tile
  height: 220px
  width: 170px
  position: relative
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  border: 1px solid #ddd
  border-radius: 4px
  padding: 10px
  margin: 10px

.gallery-form--fields
  display: flex
  flex-direction: row
  flex-wrap: wrap

.gallery-form--images
  display: flex
  flex-direction: row
  flex-wrap: wrap

.gallery-form--image
  +gallery-image-tile

  &.hidden
    display: none

.gallery-form--file-field-label
  +gallery-image-tile

  &:hover
    background-color: #eee
    cursor: pointer

  &.-hidden
    display: none

.gallery-form--file-field-label-text
  font-size: 120px


.gallery-form--thumbnail
  width: 150px
  height: 150px
  background-color: #fff
  overflow: hidden
  border: 1px solid #ddd
  border-radius: 4px
  object-fit: contain
  margin-top: 15px

.gallery-form--remove
  display: flex
  justify-content: center
  align-items: center

  width: 25px
  height: 25px
  position: absolute
  top: 5px
  right: 5px
  margin: 10px

  background-color: #ddd
  border: 1px solid white
  border-radius: 20px

  &.-hidden
    display: none

  .gallery-form--remove-x
    font-size: 20px
    color: black

  &:hover
    text-decoration: none
    .gallery-form--remove-x
      color: red


.gallery-form--title-input
  margin-top: 10px

.gallery-form--title-label[hidden]
  display: none

.gallery-form--file-field
  display: none
