@import ../environment

.screening--sidebar
  height: 85vh
  position: sticky
  top: 6.4rem
  overflow: auto

.screening--breadcrumbs
  column-count: 2
  list-style-type: none
  padding: 0
  +media-breakpoint-down(lg)
    column-count: 1
