@use "sass:math"
@import ../environment

.message
  width: 100%
  display: block
  margin-bottom: $spacer
  word-wrap: break-word

  &:last-child
    margin-bottom: 0

  +media-breakpoint-up(md)
    display: flex
    justify-content: space-between
    flex-flow: row wrap

.message--subject,
.message--sender
  margin-bottom: math.div($spacer, 2)

.message--subject
  flex: 1 1 0

  +media-breakpoint-up(md)
    margin-right: $spacer

.message--sender
  flex: 0 1 auto
  font-size: $small-font-size

.message--body
  flex: 1 1 100%
  padding: math.div($spacer, 4) $spacer
  border-left: .25rem solid $gray-200
  margin: 0
