@import ../environment

$spacing: 1.0em

.cookies
  position: fixed
  bottom: $spacing
  right: $spacing
  z-index: 99999999
  background-color: white

  box-sizing: border-box
  width: 340px
  max-width: calc(100% - (2 * #{$spacing}))
  padding: $spacing

  border-top: 4px solid $green
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2)

.cookies--actions
  margin-top: $spacing * 0.6

.cookies--accept
  margin: 0
