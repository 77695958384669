@import ../environment

.form-control
  &.-narrow
    width: 6rem

  &.-money
    width: 7.2rem
    text-align: right

  &.-date-picker
    max-width: 10rem
    &.-with-day-of-week
      max-width: 13rem

  &.-time-picker
    max-width: 8rem
