@import ../environment

up-modal[size=medium] up-modal-box
  width: 55rem
  max-width: 100%

up-modal-box
  padding: 36px
  border-radius: $border-radius

  box-shadow: 0 .5rem 2.5rem 1rem rgba(#000, .3)

  +media-breakpoint-down(sm)
    box-shadow: 0 .5rem 1.5rem 0.5rem rgba(#000, .3)

up-modal-dismiss
  font-size: 36px
