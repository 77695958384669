.input-group
  // Bootstrap by default wraps .input-group contents to support adding error messages
  // inside the element but show them underneath.
  //
  // We often use input group addons to place currency symbols next to money fields.
  // Since a specific `width` is configured for those, placing such groups inside a table might cause
  // the addon to wrap on smaller viewports which is terrible. Instead, we want the table cell to grow.
  //
  // Rather than applying `.flex-nowrap` to most/all input groups, we disable the default behavior.
  flex-wrap: nowrap
