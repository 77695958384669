@import ../environment

.dashboard-element
  overflow: hidden
  z-index: 0

  transition: box-shadow .2s ease-out
  box-shadow: 0 0 0 rgba(#000, 0)
  &:hover
    box-shadow: 0 0 0 0.4rem rgba(#000, .1)

.dashboard-element--icon
  z-index: -1
  color: rgba($text-muted, 0.07)
  text-align: center
  font-size: 16rem
  line-height: 1
  position: absolute
  left: -5.5rem
  bottom: -4rem
