=nav-button
  position: absolute
  display: flex
  justify-content: center
  align-items: center

  width: 50px
  height: 50px
  border-radius: 25px

  background-color: transparentize(#ddd, 0.5)
  color: #aaa
  font-size: 20px

  &:hover
    text-decoration: none
    background-color: transparentize(#bbb, 0.2)
    color: black

.gallery-image
  position: relative
  display: flex
  justify-content: center
  align-items: center
  margin: 20px

.gallery-image--container
  display: flex
  flex-direction: column
  align-items: center
  max-width: 100%
  max-height: 100%

.gallery-image--image
  max-width: 80%
  max-height: 80%
  object-fit: contain
  margin-top: 30px
  min-width: 400px

.gallery-image--title, .gallery-image--description
  font-size: 20px
  margin: 20px
  max-width: 100%
  overflow: hidden

.gallery-image--title
  text-align: center

.gallery-image--description
  min-width: 400px
  width: 80%

.gallery-image--filename
  font-size: 20px
  margin: 20px
  max-width: 100%
  overflow: hidden
  text-align: center

  &:before
    content: 'Datei: '

.gallery-image--previous
  left: 20px
  +nav-button

.gallery-image--next
  right: 20px
  +nav-button
