@use "sass:math"
@import ../environment

.report--table-container
  width: 100% // fill the flex box
  overflow-x: auto // wide tables cannot wrap

  @media print
    overflow-x: visible

.report--table
  .report[data-report-columns='custom_period'] &
    width: auto

  td, th
    font-size: 90%
    +padding-y(math.div($table-cell-padding, 2))
    border-right: 1px solid $gray-200

    &:first-child
      padding-left: 0
      border-right-width: 2px

    &:last-child
      border-right: none
