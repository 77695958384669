@use "sass:math"
@import ../environment

.form-actions
  +media-breakpoint-up(xl)
    display: flex

  @media print
    display: none

  .btn
    vertical-align: top
    margin-bottom: math.div($spacer, 2)
    white-space: nowrap
    &:not(:last-child)
      margin-right: math.div($spacer, 4)
    +media-breakpoint-down(sm)
      display: block
      width: 100%
      +ellipsis

.form-actions--primary
  +media-breakpoint-up(xl)
    flex: 100%

.form-actions--secondary
  +media-breakpoint-up(xl)
    text-align: right

    .btn
      &:not(:last-child)
        margin-right: 0
        margin-left: math.div($spacer, 4)
