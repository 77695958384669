@import ../environment

.btn
  cursor: pointer

  &.disabled,
  &:disabled
    cursor: not-allowed

  &[disabled][data-disable-with]
    cursor: wait

  [disabled] &
    cursor: default

  &:not(.btn-link)
    &:not([class*='btn-outline'])
      &.btn-sm
        box-shadow: $btn-box-shadow-sm

  &[class*='btn-outline']
    box-shadow: none

  &.nav-link
    box-shadow: none !important

[no-edit]
  .form-group.btn-group
    > .btn
      &:not(.active)
        +hover-focus
          background-color: #fff

label.btn
  margin-bottom: 0
