@import ../environment

=banner($color-name)
  $background: theme-color-level($color-name, $alert-bg-level)
  $border: theme-color-level($color-name, $alert-border-level)
  $text: theme-color-level($color-name, $alert-color-level)
  +banner-colors($background, $border, $text)

=banner-colors($background, $border, $text)
  +alert-variant($background, $border, $text)
  a
    color: $text
    font-weight: bold
    text-decoration: underline
    &:hover
      color: darken($text, 15%)

.banner
  padding: $alert-padding-y $alert-padding-x
  margin-bottom: $alert-margin-bottom
  border: $alert-border-width solid transparent
  +border-radius($alert-border-radius)
  +banner-colors($light, $border-color, $body-color)

.banner-warning
  +banner('warning')

.banner-danger
  +banner('danger')

.banner-info
  +banner('info')
