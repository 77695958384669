@import ../environment

$container-lg-inner-width: calc(map-get($container-max-widths, lg) - $grid-gutter-width / 2)
$container-xl-inner-width: calc(map-get($container-max-widths, xl) - $grid-gutter-width / 2)

.flashes
  max-width: calc(100% - 2rem)
  opacity: 0.95
  pointer-events: none

  @media print
    display: none

  +not-in-tests
    position: fixed
    z-index: 99999
    top: 1rem
    right: 1rem
    +media-breakpoint-up(lg)
      right: calc((100vw - #{$container-lg-inner-width}) / 2)
    +media-breakpoint-up(xl)
      right: calc((100vw - #{$container-xl-inner-width}) / 2)

  .layoutless &
    position: static

.flashes--flash
  pointer-events: auto
  width: 40rem
  max-width: 100%
  margin: 0 0 .5rem auto
  word-wrap: break-word
  +box-shadow(.3rem .3rem 0 rgba(#666, .1))

  a
    color: inherit
    font-weight: bold
    text-decoration: underline
