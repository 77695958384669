@import ../environment

.process-steps
  display: flex
  justify-content: space-between
  margin: 2rem 0
  width: 100%

.process-step
  display: flex
  align-items: center

.process-steps--separator
  flex-grow: 1
  height: 1px
  margin: 1rem .6rem
  content: ""
  background-color: $gray-200


.process-step--label
  margin-left: 10px

  .process-step.-current > &
    font-weight: bold

.process-step--circle
  height: 2rem
  width: 2rem
  background-color: $gray-600
  border-radius: 50%
  display: inline-block
  display: flex
  justify-content: center
  align-items: center
  color: white

  .process-step.-current > &
    background-color: $dark

  .process-step.-finished > &
    background-color: $success
