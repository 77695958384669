@import ../environment

.table
  margin-bottom: $spacer

  thead
    tr:first-child
      th
        border-top: 0

.table-fixed
  +media-breakpoint-up(lg)
    table-layout: fixed

.card,
.collapsible--body,
.list-group-flush
  > .table,
  > .table-responsive > .table
    > thead,
    > tbody
      > tr
        > th,
        > td
          &:first-child
            padding-left: $card-spacer-x
          &:last-child
            padding-right: $card-spacer-x

  > .table,
  > .table-responsive
    margin-bottom: 0

.-thick-bottom-border
  border-bottom: 2px solid $gray-200

.-thick-top-border
  border-top: 2px solid $gray-200

.-double-bottom-border
  border-bottom: 6px double $gray-200
