@import ../environment

.table-responsive
  // .table-responsive sets "display: block" on all breakpoints. Tables will
  // become only as wide as their contents which looks odd on desktop screens.
  // It could be by design, but not what we want.
  //
  // Instead, we assign the following styles to allow using .table-responsive
  // like in Bootstrap 3:
  //
  //     .table-responsive
  //       .table.table
  //         ...
  //
  // You may still use ".table.table-responsive" if you like.
  //

  position: relative // prevent the browser from drawing absolutely positioned elements (e.g. with sr-only class) outside the visible area (e.g. in scrollable tables)
  margin-bottom: $spacer
  > table
    margin-bottom: 0

  @media print
    overflow: visible
