@import ../environment

.dynamic-form-group
  &.form-group:last-child,
  .form-group,
  .custom-control,
  label
    margin-bottom: 0

.dynamic-form-group--headline
  font-size: $font-size-base
  font-weight: bold
  margin-bottom: 1em

.dynamic-form-group--checkbox
  width: 33%

.dynamic-form-group--collection-item
  margin-right: $grid-gutter-width
  &:last-child
    margin-right: 0
