@import ../environment

.dp
  &::before
    background: $primary

  .dp-day-today::after
    border: 7px solid darken($primary, 10%)
    border-bottom-color: transparent
    border-left-color: transparent

  .dp-selected, .dp-current
    background: $primary

  :is(.dp-days, .dp-cal-header, .dp-cal-footer) button:not(.dp-selected)
    &:hover
      background: lighten($primary, 25%)

  .dp-selected
    button:hover
      background: $primary !important
