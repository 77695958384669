@import ../environment

.layoutless
  min-height: 100vh
  padding: $spacer

  display: flex
  flex-direction: column

  align-items: center
  background-color: rgba(#fff, .75)

.layoutless--header
  align-self: flex-start center

.layoutless--content
  flex: 100%

  width: 27rem
  max-width: 100%

  display: flex
  flex-direction: column
  justify-content: center

