@import ../environment

.custom-file
  > input
    cursor: pointer
    +only-in-tests
      opacity: 0.5
      position: absolute
      z-index: 999

.custom-file-control
  +text-truncate

  &:not(:empty)
    &:after
      display: none
