@use "sass:math"
@import ../environment

$rows-breakpoint: md

.totals
  color: $text-muted
  padding-left: $table-cell-padding
  padding-right: $table-cell-padding
  margin-bottom: math.div($spacer, 2)
  display: flex
  flex-direction: column
  min-height: 1.5em
  +media-breakpoint-up($rows-breakpoint)
    flex-direction: row

.totals--total-entries,
.totals--amount
  margin-right: 2rem
  &:last-child
    margin-right: 0

.totals--amount
  +media-breakpoint-up($rows-breakpoint)
    text-align: right
    .totals--total-entries + &
      margin-left: auto
