@import ../environment

.nav-link

  &:focus
    outline: none

  &.btn
    border-width: 0
    &:focus
      box-shadow: none

  &.active
    .navbar-dark &
      background-color: darken($dark, 15%)

    .navbar-light &
      background-color: rgba($body-color, .05)

  &.-external
    color: darken($green, 5) !important
