@import ../environment

.report-form
  margin-top: 1rem
  margin-bottom: 2rem

  // Don't make the opacity flash if the server is fast to respond
  transition: opacity 0.8s ease

  // Indicate that the server is working
  &.active
    opacity: 0.5

.report-form--comment
  color: $gray-600
  font-size: 12px
  line-height: 16px
