@use "sass:math"
@import ../environment

.filter--layer
  width: 100%
  margin-bottom: math.div($spacer, 2)
  padding-right: math.div($spacer, 2)

  .form-group
    margin-bottom: 0.5rem
