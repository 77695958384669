@import ../environment

.receipt-details
  padding: 0
  border: none
  position: relative

  td
    border: none

.receipt-details--container
  background-color: $gray-300
  padding: 10px
  border: 1px solid $gray-400

.receipt-details--close
  position: absolute
  display: block
  top: 15px
  right: 30px
  color: $text-muted
