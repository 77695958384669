@import ../environment

.sr-label
  +not-in-tests
    +sr-only

  +only-in-tests
    font-size: $small-font-size
    color: $text-muted
    outline: 1px dashed $text-muted
