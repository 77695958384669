@import ../environment

.list-group-item
  display: block // v4 Alpha uses flex, which is terrible. Will be block in v4 Beta

.list-group-item-separator
  padding: 1px
  margin: 0
  line-height: 0
  list-style-type: none

  border-top: $list-group-border-width solid $list-group-border-color
