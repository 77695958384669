@use "sass:math"
@import ../environment

.actions-container
  width: 100%
  display: flex
  flex-wrap: wrap
  align-items: flex-end

.actions-container--badge
  margin-left: auto
  display: flex
  flex-direction: column
  align-items: flex-end
  margin-bottom: math.div($spacer, 3) // like .page-header--extra .btn
