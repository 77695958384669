@import ../environment

$toggle-right: $card-spacer-x
$collapsed-toggle-top: $card-spacer-y
$expanded-toggle-top: $card-spacer-y * 0.9

.collapsible--toggle
  position: relative
  padding-right: 3rem
  cursor: pointer
  &:before
    position: absolute
    top: $expanded-toggle-top
    right: $toggle-right
    text-decoration: none !important
    color: $body-color
    +icon($fa-var-caret-up)
    .collapsible[data-collapsed] &
      top: $collapsed-toggle-top
      +icon($fa-var-caret-down)

.collapsible--body
  .collapsible[data-collapsed] &
    display: none

.collapsible--placeholder
  display: none
  .collapsible[data-collapsed] &
    display: block

