@import ../environment

.tour-points
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-end
  padding-top: 1rem
  padding-bottom: 1.25rem
  height: 100%
  +media-breakpoint-down(sm)
    display: none


.tour-points--interim-destinations
  font-size: 2rem
  font-weight: bold
  writing-mode: vertical-lr
  line-height: 4px
