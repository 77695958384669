@use "sass:math"
@import ../environment

$page-header-flex-up: lg

.page-header
  line-height: 1.2em
  width: 100%

  font-size: 2.05rem

  border-bottom: 1px solid rgba(#000, .125)
  margin-bottom: 0.8rem

  +media-breakpoint-up($page-header-flex-up)
    display: flex
    flex-wrap: wrap

  +media-breakpoint-down(lg)
    font-size: 1.55rem

  +media-breakpoint-down(sm)
    font-size: 1.4rem

  .content > &
    // Add 2px to align with cards in navigation on the left
    line-height: calc(3rem + 2px)

    +media-breakpoint-down(sm)
      line-height: 1.5em

.page-header--headline
  flex: 9999 1 auto // hack to grow page-header--extra only on a wrap https://joren.co/flex-grow-9999-hack/
  margin-right: auto
  margin-top: auto

.page-header--extra
  flex: 1 1 auto

  margin-top: 0.3rem
  vertical-align: bottom
  font-weight: normal
  font-size: 1rem
  line-height: 1.15

  @media print
    display: none

  +media-breakpoint-up($page-header-flex-up)
    text-align: right
    display: flex
    flex-wrap: wrap
    align-items: flex-start

  +media-breakpoint-less($page-header-flex-up)
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-bottom: .25rem

    +media-breakpoint-down(xs)
      flex-direction: column
      justify-content: space-between

  .btn
    margin-bottom: math.div($spacer, 3)
    &:not(:last-child)
      +media-breakpoint-up($page-header-flex-up)
        margin-right: math.div($spacer, 4)
      +media-breakpoint-down($page-header-flex-up)
        margin-right: math.div($spacer, 5)

  .list-mode-buttons
    .btn
      margin-right: 0
