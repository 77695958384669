@import ../environment

.icon-text
  margin-left: .4rem

  .page-header &
    +not-in-tests
      +media-breakpoint-down(sm)
        display: none
  .page-header .btn-dark &
    +not-in-tests
      +media-breakpoint-only(lg)
        display: none
      +media-breakpoint-only(md)
        display: none

.text-hidden-below-lg
  .icon-text
    +not-in-tests
      +media-breakpoint-down(lg)
        display: none

.text-hidden-below-md
  .icon-text
    +not-in-tests
      +media-breakpoint-down(md)
        display: none
