@import ../environment

.templatable
  position: relative

.templatable--menu-opener
  float: right

.templatable--list
  margin-top: $spacer
  margin-bottom: $spacer
  border: 3px solid $gray-200

.templatable--spacer
  height: 32px
